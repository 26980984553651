@import '../../styles/variables';
@import '../../styles/mixin';

.search_box {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;

  @include media(lg) {
    min-width: 250px;
    max-width: 362px;
    margin: 0;
  }

  @include media(xl) {
    margin-left: auto;
    max-width: 336px;
  }

  @include media(2xl) {
    max-width: 396px;
  }

  @include media(3xl) {
    max-width: 582px;
  }

  &__input {
    flex-grow: 1;
    border-radius: $header-input-border-radius !important;
    border: 1px solid $header-input-border-color !important;
    background-color: $header-input-background !important;
    border-right: 0px;
    padding: 11px 48px 11px 0px;
    font-size: $text-base !important;

    @media screen and (max-width: 1024px) {
      font-size: $text-sm !important;
      height: 40px !important;
      padding: 8px 48px 8px 0px !important;
    }

    &:hover {
      border: 1px solid $header-input-border-color-activate !important;
    }

    &:focus-within {
      border: 1px solid $header-input-border-color-activate !important;

      .input__label {
        color: $header-input-border-color !important;
      }

      input::placeholder {
        color: $header-input-placeholder-color !important;
      }
    }

    input[type='search'] {
      color: $header-input-text-color !important;
    }

    input {
      .input__control {
        padding: 10px 54px 10px;
      }
    }
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    -webkit-user-modify: read-write !important;
    border-left: 1px solid $header-input-separator-color !important;
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $header-input-clear-color + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  }

  .search_box__button {
    position: absolute;
    right: 4px;
    border-radius: calc($header-input-border-radius - 2px);
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
    color: $header-button-icon-color;
    background: $header-button-search-color;
    border: 1px solid $header-button-search-color;

    @media screen and (max-width: 1024px) {
      height: 34px !important;
      width: 34px !important;

      svg {
        height: 18px !important;
        width: 18px !important;
      }
    }

    &:not(:disabled):hover {
      color: $header-button-icon-hover-color;
      background: $header-button-search-hover-color;
      border: 1px solid $header-button-search-hover-color;
    }

    &:not(:disabled):focus {
      color: $header-button-icon-hover-color;
      background: $header-button-search-hover-color;
      border: 1px solid $header-button-search-hover-color;
    }
  }

  button {
    svg {
      * {
        stroke: $header-button-icon-color !important;
      }
    }

    &:hover {
      svg {
        * {
          stroke: $header-button-icon-hover-color !important;
        }
      }
    }
  }
}

.search__container {
  background: $search-header-background;
  backdrop-filter: $search-header-backdrop-filter;
  height: auto;
  left: 0;
  min-height: 460px;
  position: absolute;
  top: 70%;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.08);

  &.embedded_input {
    top: 0;
  }

  .search__body {
    position: relative;
    width: 100%;
    border-color: $search-border-color;
    border-style: solid;
    display: flex;
    flex-direction: column;
  }

  .search__header {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    &__box {
      background-color: inherit;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      flex-grow: 1;
      display: flex;
      position: relative;

      @include media(md) {
        padding-left: 35px;
      }

      &::before {
        background: transparent
          url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.22218 1.66496C4.15301 1.66496 1.66496 4.15301 1.66496 7.22218C1.66496 10.2914 4.15301 12.7794 7.22218 12.7794C10.2914 12.7794 12.7794 10.2914 12.7794 7.22218C12.7794 4.15301 10.2914 1.66496 7.22218 1.66496ZM0.334961 7.22218C0.334961 3.41848 3.41848 0.334961 7.22218 0.334961C11.0259 0.334961 14.1094 3.41848 14.1094 7.22218C14.1094 11.0259 11.0259 14.1094 7.22218 14.1094C3.41848 14.1094 0.334961 11.0259 0.334961 7.22218Z' fill='%23" + $search-icon-color + "'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4184 11.4187C11.6781 11.159 12.0992 11.159 12.3589 11.4187L15.47 14.5298C15.7297 14.7895 15.7297 15.2105 15.47 15.4702C15.2103 15.7299 14.7892 15.7299 14.5295 15.4702L11.4184 12.3591C11.1587 12.0994 11.1587 11.6783 11.4184 11.4187Z' fill='%23" + $search-icon-color + "'/%3e%3c/svg%3e")
          repeat scroll 0% 0%;
        background-size: 25px;
        content: '';
        height: 25px;
        left: -4px;
        margin-top: -0.5rem;
        position: absolute;
        top: 7px;
        width: 25px;
      }

      button {
        font-size: $text-2xl;
        border: none;
      }

      &__input {
        background-color: inherit !important;
        border: 0 !important;
        box-shadow: none;
        font-size: $text-base;
        font-weight: 400;
        padding-left: 27px;
        width: 70%;
        color: $search-header-input-color;

        @include media(md) {
          padding-left: 0;
          max-width: 380px;
        }

        &::placeholder {
          align-items: center;
          color: get-value($gray-300, $search-header-input-placeholder-color);
          display: flex;
          font-size: $text-base;
          font-weight: 400;
          line-height: 24px;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }

  .line {
    border-top: $search-border-width solid $search-border-color;
    height: 2px;
    margin: 0 auto 0 auto;
    padding: 0;
    transform: translateY(80px);
  }

  .search__results-body {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 50px 0 35px;

    .embedded_input {
      padding-top: 35px;
    }

    i,
    button {
      border: none !important;
    }

    @include media(md) {
      display: grid;
      grid-template-columns: 2fr 5fr;
    }

    .search__body_action {
      position: absolute;
      padding: 0;
      top: 6px;
      right: 0px;
      margin: 10px 0 0;
    }

    .empty_results {
      justify-content: center;
      align-self: center;
      align-content: center;
      grid-column-start: 1;
      grid-column-end: 3;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 400px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 40px;
      color: $search-header-empty-results-color;

      i {
        font-size: $text-3xl;
      }

      h5 {
        color: $search-header-empty-results-color !important;
      }
    }

    .heading-2 {
      align-items: center;
      color: $search-title-color;
      display: flex;
      flex: none;
      font-size: $text-xl;
      font-weight: 700;
      line-height: 1.5rem;
      margin: 0;
    }

    .search__suggestions {
      @include media(md) {
        width: 200px;
      }

      @include media(lg) {
        width: 225px;
      }

      @include media(xl) {
        width: 225px;
      }

      .suggestion__hits {
        border-right: 0.5px solid $gray-200;
        display: none;
        padding: 0 40px 18px 0;

        @include media(md) {
          display: flex;
          flex-direction: column-reverse;
        }

        &-empty {
          display: none;
        }

        ol {
          padding: 20px 0;
          li {
            box-shadow: none;
            font-size: $text-base;
            font-weight: 400;
            line-height: 2.125rem;
            padding: 0;
            color: get-value($body-color-text, $search-link-color);

            &:hover {
              text-decoration: underline;
              text-underline-offset: 3px;
            }
            .highlighted {
              color: $primary;
            }
          }
        }

        .suggestion_hits-placeholder {
          width: 100%;
          height: 30px;
        }
      }
    }
    .search__results {
      width: -webkit-fill-available;
      position: relative;
      @include media(md) {
        width: calc(100vw - 282px);
        max-width: 528px;
        padding-left: 40px;
      }
      @include media(lg) {
        max-width: 784px;
      }
      @include media(xl) {
        max-width: 1021px;
      }
      @include media(2xl) {
        max-width: 1272px;
      }
      @include media(3xl) {
        max-width: 1783px;
      }

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .products__see-all {
        align-items: center;
        color: $search-see-all-link-color;
        display: flex;
        font-size: $text-sm;
        font-weight: 500;
        line-height: 1.125px;
        text-align: center;
        text-transform: uppercase;
        justify-content: flex-end;
      }
      .search__hits {
        display: flex;
        flex-direction: column-reverse;

        &-empty {
          display: none;
        }

        > div {
          margin: 20px 0 40px;
        }

        .search__custom-hits {
          .search_product-card {
            height: 100%;
            background: $background-product-card-header !important;

            .product__show_more {
              &_body {
                z-index: 30px;
              }
            }
          }
        }

        .search__hits-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.search__icon {
  svg {
    width: 24px !important;
    height: 24px !important;

    @media screen and (max-width: 400px) {
      width: 24px !important;
      height: 24px !important;
    }

    * {
      stroke: $header-action-icon-color;
    }
  }
}
